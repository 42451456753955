import './Menu.css'

export default function Projects({setFlag}) {
    const handlerHide=()=>{
        setFlag(true)
    }


    return(
        <nav className={'z-2 d-flex justify-content-center align-items-center ' }>
            <ul>
                <li className='text-center pb-5' onClick={()=>handlerHide()}>
                    <a className="text-decoration-none text-black" href="#skills">
                        SKILLS
                    </a>
                </li>
                <li className='text-center pb-5' onClick={()=>handlerHide()}>
                    <a className="text-decoration-none text-black" href="#projects">
                        PROJECTS
                    </a>
                </li>
                <li className='text-center pb-5' onClick={()=>handlerHide()}>
                    <a className="text-decoration-none text-black" href="#edAndWork">
                        EDUCATION & WORK
                    </a>
                </li>
                <li className='text-center' onClick={()=>handlerHide()}>
                    <a className="text-decoration-none text-black" href="#contact">
                        CONTACT    
                    </a>
                </li>
            </ul>
        </nav>
    )
}