import './Contact.css'

export default function Contact() {
    return (
        <section className="padding-section" id='contact'>
            <h2 className="subtitle text-center ">Let’s connect</h2>
            <div className="d-flex contianer-distance contianer-contact">
                <div className='border-dark border-end  border-bottom border-contact pe-123'>
                    <p className="fw-500">Interested in working together or just have a conversation ?</p>
                    <p className="fw-500">Send me a massege here or find me on social media</p>
                    <div className="d-flex gap-media pt-5">
                        <a href="https://wa.me/0111536332941?text=H1!%20 I'm%20interested%20in%20get%20in%20connect%20width%20you" className="text-decoration-none" target='__blank'><img className="media-icon" src="./images/icon-smedia/whatsapp.svg" alt="icon whatsapp"/></a>
                        <a href="https://www.linkedin.com/in/m-victoria-lopez/" className="text-decoration-none" target='__blank'><img className="media-icon" src="./images/icon-smedia/linkedin.svg" alt="icon linkedin"/></a>
                        <a href="https://github.com/Victoria-Lopez-dev" className="text-decoration-none" target='__blank'><img className="media-icon" src="./images/icon-smedia/github.svg" alt=" icon github"/></a>
                    </div>
                </div>
                <form method="POST" className='ps-123 w-100 d-flex flex-column gap-4 align-items-start form-size'>
                    <div className="form-floating w-100 ">
                        <input type="text" className="form-control" id="floatingName" placeholder="Name"/>
                        <label htmlFor="floatingName">Name</label>
                    </div>            
                    <div className="form-floating w-100 mb-3 ">
                        <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com"/>
                        <label htmlFor="floatingEmail">Email</label>
                    </div>
                    <div className="form-floating w-100 mb-3">
                        <textarea className="form-control" placeholder="Leave a comment here" id="floatingMessage" ></textarea>
                        <label htmlFor="floatingMessage">Message</label>
                    </div>

                    <input type='submit' value="Send Message" className='btn btn-form fw-bold'/>
                </form>
            </div>
        </section>
    )
}