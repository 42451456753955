import './Header.css'

export default function Header() {
    return(
        <header className="d-flex header flex-column justify-content-between align-items-center padding-section">
        
            <div> 
                <h1>I ‘m Victoria .</h1>
                <p className='text-header text-end'>I'm an Argentinian  full-stack developer with an orientation for front-end.</p>
                <p className='text-header text-end'>I’m  passion for learning and  problem-solving; working  with other teams ,or on my own as a freelancer , to transform ideas to  responsive interfaces .</p>
            </div>
            <div className='position-relative container-img '>
                <img className='profile-size z-1 position-relative' src='./images/perfil.21.jpg' alt='profile Maria Victoria Lopez'/>
                <div aria-hidden='true' className="position-absolute profile-size b-black z-0"></div>
                <div aria-hidden='true' className="position-absolute profile-size b-bourbon z-0"></div>
            </div>
            
        </header>
    )
}