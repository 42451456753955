import { Fragment, useEffect, useState } from 'react';
import Header from './components/Header';
import Skills from './components/Skills';
import Projects from './components/Projects';
import EdAndWork from './components/Education&Work';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Menu from './components/Menu';

import figure1 from './background-imgs/figure1.svg';
import figure2 from './background-imgs/figure2.svg';
import figure3 from './background-imgs/figure3.svg';
import figure4 from './background-imgs/figure4.svg';
import figure5 from './background-imgs/figure5.svg';
import './Home.css';


function Home() {
  const[turnRight,setTurnRight]=useState("");
  const[turnLeft,setTurnLeft]=useState("");
  const[hide,setHide]=useState("");
  const [flag,setFlag]=useState(true)

  const handlerHambMenu=()=>{
   
    setFlag(!flag)
  }

  useEffect(()=>{
     if(flag ===false){
      setTurnRight("turn-right")
      setTurnLeft("turn-left")
      setHide("invisible")      
    }else{
      setTurnRight("")
      setTurnLeft("")
      setHide("visible") 
    }
  },[flag])
  
  return (
    <Fragment>
     {flag === false? <Menu setFlag={setFlag}/>:''}

      <div className='hamburger position-fixed z-2 d-flex flex-column justify-content-between' onClick={handlerHambMenu}>
          <div className={'line-hamb rounded '+ turnRight}></div>
         <div className={'line-hamb rounded '+ turnLeft}></div>
         <div className={'line-hamb rounded '+ hide}></div>
      </div>
  
      {/* background figures */}
      <img className='position-absolute size-figures z-n1 top-0 figure1 ' aria-hidden="true" src={figure1} alt=""/>
      <img className="position-absolute size-figures z-n1 end-0 figure2 " aria-hidden="true" src={figure2} alt=""/>

      <Header/>
      <Skills/>
      <Projects/>
      <EdAndWork/>
      <Contact/>
       {/* background figures */}
      <img className="position-absolute size-figures z-n1 start-0  figure3" aria-hidden="true" src={figure3} alt=""/>
      <img className="position-absolute size-figures z-n1 end-0 figure4" aria-hidden="true" src={figure4} alt=""/>
      <img className="position-absolute size-figures z-n1 end-0 figureBis " aria-hidden="true" src={figure2} alt=""/>
      <img className="position-absolute size-figures z-n1 bottom-0 start-0 figure5" aria-hidden="true" src={figure5} alt=""/>
        <Footer/>
      </Fragment>
  );
}

export default Home;
