import './Skills.css'

export default function Skills() {
    return (
        <section className=" container-fluid section padding-section" id="skills">
            <h2 className="subtitle justify-content-center row m-0">Skills</h2>
            <div className="row contianer-distance gap-4 justify-content-center container-skills">

                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/html5.png" alt="HTML5"/>
                    <p className="text-skill">HTML5</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/css3.png" alt="CSS3"/>
                    <p className="text-skill">CSS3</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/javascript.png" alt="JAVASCRIPT"/>
                    <p className="text-skill">JAVASCRIPT</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/jquery.png" alt="JQUERY"/>
                    <p className="text-skill">JQUERY</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/sass.png" alt="SASS"/>
                    <p className="text-skill">SASS</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/bootstrap.png" alt="BOOTSTRAP"/>
                    <p className="text-skill">BOOTSTRAP</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/react.png" alt="REACT"/>
                    <p className="text-skill">REACT</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/git.png" alt="GIT"/>
                    <p className="text-skill">GIT</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/github.png" alt="GITHUB"/>
                    <p className="text-skill">GITHUB</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/nodeJS.png" alt="NODEJS"/>
                    <p className="text-skill">NODEJS</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/typescript.png" alt="TYPESCRIPT"/>
                    <p className="text-skill">TYPESCRIPT</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/ejs.png" alt="EJS"/>
                    <p className="text-skill">EJS</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/figma.png" alt="FIGMA"/>
                    <p className="text-skill">FIGMA</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/mysql.png" alt="MYSQL"/>
                    <p className="text-skill">MYSQL</p>
                </div>
                <div className="col-md-2 col-sm-1 d-flex flex-column align-items-center justify-content-center gap-8">
                    <img className="img-skill" src="./images/icon-skills/mongodb.png" alt="MONGODB"/>
                    <p className="text-skill">MONGODB</p>
                </div>
            </div>

        </section>
    )
}