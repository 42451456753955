import './edItem.css'

export default function EdItem({edInfo}) {
    return (
        <div className='p-info-w-e'>
           <h4 className='title m-0'>{edInfo.title}</h4>
            <div className="d-flex">
                <p className="border-end border-dark text-edAndWork pe-1">{edInfo.institud}</p>
                <p className='text-edAndWork ps-1'>{edInfo.year}</p>
            </div>
        </div>
    )
}
