import WorkItem from "../WorkItem"
import EdItem from "../EdItem"
import { useState } from "react"

import"./Education&Work.css"

export default function EdAndWork() {
    const [educationI]=useState([
        {
            id:"1",
            title:"REACT JS",
            institud:"E-learning platform Udemy",
            year:"2022"
        },
        {
            id:"2",
            title:"ARGENTINA PROGRAMA #YoProgramo",
            institud:"Productive Development Ministry Program",
            year:"2022"
        },
        {
            id:"3",
            title:"ARGENTINA PROGRAMA #SeProgramar",
            institud:"Productive Development Ministry Program",
            year:"2021"
        },
        {
            id:"4",
            title:"DEVELOPING WEB FULL-STACK",
            institud:"Acamica Academy",
            year:"2020"
        },
        {
            id:"5",
            title:"REACT",
            institud:"Wes Bos Courses",
            year:"2020"
        }
        ,
        {
            id:"6",
            title:"SCRUM MASTER",
            institud:"SCRUM MASTER",
            year:"2020"
        }
    ])
    const [experience]=useState([        
        {
            id:"1",
            name:"FREELANCER",
            period:"[2020 - Present]",
            description:"Project development and collaboration with other teams to transform ideas to  interfaces ."
        },
        {
            id:"2",
            name:"INSTRUCTOR  IN ELEARNING AND WEB COURSES OF FRONTEND AND  FULL STACK OF UTN ",
            period:"[2021 - Present]",
            description:"Giving lessons of lenguages, implementations and good practices;preparing materials to keep up width trends, correcting work, etc."
        },
        {
            id:"3",
            name:"ASSISTANT  IN WEB COURSES - UTN",
            period:"[04/2021 - Present]",
            description:"Assist the instructor, resolving student queries, and correcting work to contribute to the quality of the course."
        },
        {
            id:"4",
            name:"ACCOUNT AND CASH ANALYST",
            period:"[03/2018 –10/2019]",
            description:"Doing analisis in clients account,doing control of stock,preparing  Credit Notes and documentation."
        }
    ])

    return (
        <section className=" distance-section-top container-fluid section " id="edAndWork">
            <h2 className="subtitle justify-content-center row p-info-w-e">Education & Work</h2>
            <div className="row justify-content-center align-items-center ">
                <section className="col-md-5 col-sm-12 border-end border-secondary">
                    <h3 className="title-edAndwork text-center ">Education</h3>
                    <div>
                    {educationI.map((edu)=>{
                        return <EdItem key={edu.id} edInfo={edu}/>
                    })}
                    </div>                
                </section>
                <section className="col-md-5 col-sm-12">
                    <h3 className="title-edAndwork text-center ">Experience</h3>
                    <div className="ps-experience">
                        {experience.map((exp)=>{
                            return <WorkItem key={exp.id} workInfo={exp}/>
                        })}
                    </div>                  
                </section>
          
            </div>

        </section>
    )
}